$( document ).ready(function() {

	/*******************COOOKIES**************************/
			$(".rodo").css("display","none");
			var cookieRodo = getCookie('rodo-cookie');
			if (cookieRodo === 'null'
					|| cookieRodo === ""
					|| cookieRodo === null
					|| cookieRodo === undefined
					|| cookieRodo === 'undefined') {
							$(".rodo").css("display","block");
			} else {
					$(".rodo").css("display","none");
			}
	/******************* END COOOKIES**************************/

     $("#buttonresrv").click(function (event) {
         event.preventDefault();
         var id  = $(this).attr('href'),
             top = $(id).offset().top;
         $('body,html').animate({scrollTop: top}, 1500);
     });


	/*******************Scroll to top**************************/
	$(window).scroll(function () {
	        if ($(this).scrollTop() > 300) {
	            $('#scroll').fadeIn();
	        } else {
	            $('#scroll').fadeOut();
	        }
	    });
	    $('#scroll').click(function () {
	        $('body,html').animate({
	            scrollTop: 0
	        }, 400);
	        return false;
	    });
	/******************* END Scroll to top**************************/


	/* Polish initialisation for the jQuery UI date picker plugin. */
/* Written by Jacek Wysocki (jacek.wysocki@gmail.com). */
( function( factory ) {
	if ( typeof define === "function" && define.amd ) {

		// AMD. Register as an anonymous module.
		define( [ "../widgets/datepicker" ], factory );
	} else {

		// Browser globals
		factory( jQuery.datepicker );
	}
}( function( datepicker ) {

datepicker.regional.pl = {
	closeText: "Zamknij",
	prevText: "&#x3C;Poprzedni",
	nextText: "Następny&#x3E;",
	currentText: "Dziś",
	monthNames: [ "Styczeń","Luty","Marzec","Kwiecień","Maj","Czerwiec",
	"Lipiec","Sierpień","Wrzesień","Październik","Listopad","Grudzień" ],
	monthNamesShort: [ "Sty","Lu","Mar","Kw","Maj","Cze",
	"Lip","Sie","Wrz","Pa","Lis","Gru" ],
	dayNames: [ "Niedziela","Poniedziałek","Wtorek","Środa","Czwartek","Piątek","Sobota" ],
	dayNamesShort: [ "Nie","Pn","Wt","Śr","Czw","Pt","So" ],
	dayNamesMin: [ "N","Pn","Wt","Śr","Cz","Pt","So" ],
	weekHeader: "Tydz",
	dateFormat: "dd.mm.yy",
	firstDay: 1,
	isRTL: false,
	showMonthAfterYear: false,
	yearSuffix: "" };
datepicker.setDefaults( datepicker.regional.pl );

return datepicker.regional.pl;

} ) );

$.datepicker.setDefaults( $.datepicker.regional[ "" ] );

// Initialize
		 $('#datepicker').datepicker();
     $( "#datatime1" ).datepicker($.datepicker.regional[ "pl" ]);
		 $( "#datatime2" ).datepicker($.datepicker.regional[ "pl" ] );
		 $('#carousel').owlCarousel({
	     center: true,
	     items:2,
	     loop:true,
	     margin:10,
			 nav: true,
			 autoplay:true,
			 autoplayTimeout:4000,
			 autoplayHoverPause:true,
			 responsive : {
				 0 : {
					 center: false,
					 items: 1,
				 },
				 480 : {
					 center: true,
					 items: 2,
				 },
			 }
	 });
	/**************SLIDERY******************/
	 $('#carousel_camper').owlCarousel({
		 center: true,
		 items:2,
		 loop:true,
		 margin:10,
		 nav: true,
		 autoplay: true,
			autoplayTimeout:4000,
			autoplayHoverPause:true,
			responsive : {
				0 : {
					center: false,
					items: 1,
				},
				480 : {
					center: true,
					items: 2,
				},
			}
 });
	 $('.gallery').magnificPopup({
		 delegate: 'a',
		 type: 'image',
		 tLoading: 'Ładowanie zdjęcia #%curr%...',
		 tClose: 'Zamknij (Esc)',
		 mainClass: 'mfp-img-mobile',
		 gallery: {
			 enabled: true,
			 navigateByImgClick: true,
			 tPrev: 'Poprzednie zdjęcie',
				tNext: 'Następne zdjęcie',
 			 preload: [0,1], // Will preload 0 - before current, and 1 after the current image
			 tCounter: 'Zdjęcie %curr% z %total%',
		 },
		 image: {
			 tError:  'Nie udało się otworzyc <a href="%url%">plik #%curr%</a>.',
			 titleSrc: function(item) {
				 return item.el.attr('title') + '<small>X-Camper - Galeria</small>';
			 }
		 }
	 });


			 $(document).on("scroll",function(){
		    if($(document).scrollTop()>300 && $(window).width() > 1220 ){
		        $("header").addClass("small");
						$(".topPage .wrapBanner").addClass("small");
						$(".topPage .subBanner__button").addClass("small");

		    } else{
					$(".topPage .wrapBanner").removeClass("small");
					$(".topPage .subBanner__button").removeClass("small");
					$("header").removeClass("small");
		    }
		});
		 AOS.init();

		 $(".menu--mobile").click(function () {
			 $(".mobileMenu").css("transform", "translateY(0)");
	 });

	 $(".mobileMenu").on('click', '.close_menu', function () {
			 $(".mobileMenu").css("transform", "translateY(-100%)");
	 });

	 /******************COOKIES FUNCTIONS*******************************/


 });
